<template>
  <div class="xgpaypsw">
    <title-top>修改支付密码</title-top>

    <div class="row align-center bg-white h96">
      <span class="font28 f-333333 fontweight400 ml25 space">手机号</span>
      <span class="flex1 font24 f-1A1A1A mr25">{{res.mobile}}</span>
    </div>
    <div class="line"></div>
    <div class="row align-center bg-white h96">
      <span class="font28 f-333333 fontweight400 ml25 space">验证码</span>
      <input class="flex1 font24 f-999999" v-model="sms_code" placeholder="请输入验证码" />
      <div
        v-show="sendAuthCode"
        class="yanzhegn font24 f-F62341 row flex-center mr25"
        @click="getAuthCode"
      >获取验证码</div>
      <div v-show="!sendAuthCode" class="yanzhegn_un font24 f-999999 mr25">
        <span class="auth_text_blue">{{auth_time}}</span> 秒后重新发送验证码
      </div>
    </div>
    <div class="line"></div>
    <div class="row align-center bg-white h96">
      <span class="font28 f-333333 fontweight400 ml25 space">新的支付密码</span>
      <input
        class="flex1 font24 f-999999 mr25"
        v-model="password"
        type="password"
        placeholder="请输入支付密码"
      />
    </div>
    <div class="line"></div>
    <div class="row align-center bg-white h96">
      <span class="font28 f-333333 fontweight400 ml25 space">确认支付密码</span>
      <input
        class="flex1 font24 f-999999 mr25"
        v-model="password_confirm"
        type="password"
        placeholder="再次输入支付密码"
      />
    </div>
    <div class="row mt25">
      <span class="ml25 font20 f-F62341">输入6个字母和数字组成的支付密码</span>
    </div>
    <div class="row flex-center" @click="onSure">
      <div class="btn-red mt170">确认</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";

import { member, update_pay_psw } from "network/igou";
import { sms_codes } from "network/login";

export default {
  name: "XgPayPsw",
  data() {
    return {
      res: {},
      password_confirm: "",
      sms_code: "",
      mobile: "",
      password: "",
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      auth_time: 0 /*倒计时 计数器*/
    };
  },
  mounted() {
    this.get_member();
  },
  methods: {
    /**
     * 获取短信验证码
     */
    getAuthCode() {
      if (!this.mobile) {
        this.$toast.show("请输入手机号");
        return;
      }
      sms_codes(this.mobile).then(
        res => {
          //console.log(res)
          //console.log('发送成功')
          this.$toast.show("发送成功");
          this.sendAuthCode = false;
          this.auth_time = 60;
          var auth_timetimer = setInterval(() => {
            this.auth_time--;
            if (this.auth_time <= 0) {
              this.sendAuthCode = true;
              clearInterval(auth_timetimer);
            }
          }, 1000);
        },
        err => {
          console.log(err);
        }
      );
    },
    get_member() {
      member().then(res => {
        this.res = res.data;
        this.mobile = res.data.mobile;
      });
    },
    onSure() {
      if (!this.password) {
        this.$toast.show("密码不能为空");
        return;
      }
      if (!this.password_confirm) {
        this.$toast.show("确认密码不能为空");
        return;
      }
      if (this.password != this.password_confirm) {
        this.$toast.show("密码不一致");
        return;
      }
      if (!this.sms_code) {
        this.$toast.show("验证码不能为空");
        return;
      }
      update_pay_psw(this.mobile, this.password, this.sms_code).then(res => {
        this.$toast.show("修改成功");
        this.$router.back();
      });
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.space {
  width: 2.666667rem;
}
.yzmbtn {
  border: 0.013333rem solid #f62341;
  width: 2.4rem;
  height: 0.8rem;
}
</style>
